<template>
  <div class="container">
    <h2>REGISTRAR DE CUENTA</h2>
    <input placeholder="Correo" type="text" v-model="correo" autocomplete="off" />
    <input placeholder="Contraseña" @keyup.enter="register" type="password" v-model="password" autocomplete="off" />
    <button @click="register" :disabled="!correoValido(correo) || !passwordValido(password)">REGISTRAR CUENTA</button>
    <br />
    <br />
    <div style="text-align: center" v-if="isWeb">
      Registrarse con<br />
      <button class="access-button" @click="googleAuth"><ThemifyIcon icon="google" title="Google" /></button>
    </div>
  </div>
</template>
<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut, signInWithPopup } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { Device } from '@capacitor/device';

import ThemifyIcon from 'vue-themify-icons';

export default {
  name: 'Login',
  components: {
    ThemifyIcon,
  },
  data() {
    return {
      isWeb: false,
      correo: '',
      password: '',
    };
  },
  computed: {
    correoEsValido() {
      return emailRegex.test(this.correo);
    },
  },
  mounted() {
    const este = this;
    (async function getDevice() {
      const info = await Device.getInfo();
      este.isWeb = info.platform === 'web';
    })();
    this.setLoading(false);
  },
  methods: {
    googleAuth() {
      this.providerAuth(new GoogleAuthProvider());
    },
    async providerAuth(provider) {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await signInWithPopup(auth, provider);
        this.notification('Login Aceptado');
      } catch (error) {
        this.notification(error, provider.providerId + ' Auth Error');
      }
      this.setLoading(false);
    },
    async register() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await createUserWithEmailAndPassword(auth, this.correo, this.password);
        this.notification('Registro Exitoso de ' + this.correo);
        await sendEmailVerification(auth.currentUser);
        this.notification({
          code: 'auth/not-email-verified',
          message: 'Revise su correo (' + this.correo + '), le acabamos de enviar un correo de verificación  (OJO, puede estar en la carpeta de SPAM)...',
        });
        await signOut(auth);
        this.$router.replace('/admin/login');
      } catch (error) {
        await signOut(auth);
        this.notification(error, 'Register Error');
      }
      this.setLoading(false);
    },
  },
};
</script>
